<template>
  <div class="login">
    <div class="section-title">
      <span class="left"></span>
      <span class="text">Logowanie</span>
      <span class="right"></span>
    </div>
    <div class="page-content">
      <div class="forms">
        <ValidationObserver ref="loginForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <ValidationProvider rules="required|email" v-slot="{ failedRules, classes }" slim>
              <div class="form-group" :class="classes">
                <label>e-mail podany podczas rejestracji</label>
                <input type="email" v-model="loginForm.f_email" autocomplete="off" />
                <span v-if="failedRules.required" class="error">Pole email jest wymagane</span>
                <span v-if="failedRules.email" class="error">Wprowadź poprawny adres e-mail</span>
              </div>
            </ValidationProvider>

            <ValidationProvider :rules="{ required: true }" v-slot="{ failedRules, classes }" slim>
              <div class="form-group" :class="classes">
                <label>hasło</label>
                <input type="password" v-model="loginForm.f_pass" autocomplete="off" />
                <span v-if="failedRules.required" class="error">Pole hasło jest wymagane</span>
              </div>
            </ValidationProvider>
            <div class="form-group clearfix">
              <div class="col-md-12" style="text-align: right; padding-top: 10px;">
                <Button :title="'Zaloguj'" :loading="loading" :cssClass="'pull-right'" />
              </div>
            </div>
          </form>
        </ValidationObserver>
        <div class="form-group clearfix">
          <div class="row clearfix">
            <div class="col-md-12" style="text-align: center; margin-right: 10px; margin-top: 20px;">
              <router-link tag="button" class="button" style="margin-right: 20px;" to="/przypomnienie-hasla">
                <span>Zmień hasło</span>
              </router-link>
              <!--<router-link tag="button" class="button" to="/rejestracja">
                  <span>Załóż konto</span>
                </router-link>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <Notification />
  </div>
</template>
<script>
import axios from "axios";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import pl from "vee-validate/dist/locale/pl";

for (let rule in rules) {
  extend(rule, {
    ...rules[rule],
    message: pl.messages[rule],
  });
}

export default {
  name: "logowanie",
  data: () => ({
    loginForm: {
      f_email: "",
      f_pass: "",
    },
    f: "",
    loading: false,
  }),
  created() { },
  mounted() {
    window.scrollTo(0, 0);
    console.log();
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.loginForm.validate();
      if (isValid) {
        this.loading = true;
        let data = {
          f_email: this.loginForm.f_email.toLowerCase(),
          f_pass: this.loginForm.f_pass,
        };

        axios
          .post('login', data)
          .then((response) => {
            sessionStorage.setItem("sessionKey", response.data.sessionToken);
            sessionStorage.setItem(
              "sessionData",
              JSON.stringify(response.data)
            );
            dataLayer.push({
              event: "Konto",
              eventAction: "Logowanie",
            });
            this.$router.push(this.$route.query.redirect || '/profil');
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
};
</script>