<template>
    <div class="regulamin">
        <div class="section-title">
            <span class="left"></span>
            <span class="text">Numer Seryjny Kupomu - Przykład</span>
            <span class="right"></span>
        </div>
        <div class="page-content">
            <img style="display: block; text-align: center;" src="assets/img/Kupon.png" alt="Numer Seryjny Kupomu" />
        </div>
    </div>
</template>

<script>
export default {
    name: "klauzula",
    data: () => ({}),
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {},
    watch: {},
    components: {}
};
</script>
