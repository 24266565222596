<template>
    <div class="regulamin">
        <div class="section-title">
            <span class="left"></span>
            <span class="text">Klauzula Informacyjna</span>
            <span class="right"></span>
        </div>
        <div class="page-content">
            <h2 class="text-center">
                KLAUZULA DOTYCZĄCA PRZETWARZANIA DANYCH OSOBOWYCH<br />PRZEZ TOTALIZATOR SPORTOWY SP. Z O.O.
            </h2>
            <p>
                Dane osobowe Uczestników Loterii przetwarzane są zgodnie z&nbsp;przepisami Rozporządzenia Parlamentu
                Europejskiego i&nbsp;Rady (UE) 2016/679 z&nbsp;dnia 27 kwietnia 2016 r. w&nbsp;sprawie ochrony osób
                fizycznych
                w&nbsp;związku z&nbsp;przetwarzaniem danych osobowych i&nbsp;w&nbsp;sprawie swobodnego przepływu takich
                danych oraz uchylenia
                dyrektywy 95/46/WE (ogólne rozporządzenie o&nbsp;ochronie danych) (dalej „RODO”) oraz ustawy z&nbsp;dnia 10
                maja
                2018r. o&nbsp;ochronie danych osobowych.
            </p>
            <h3>1. Administrator danych</h3>
            <p>Administratorem danych osobowych Uczestników Loterii jest Totalizator Sportowy sp. z o.o. z&nbsp;siedzibą
                w&nbsp;
                Warszawie przy ul. Targowej 25, 03-728 Warszawa (dalej „Administrator”). Z&nbsp;Administratorem można
                skontaktować się przesyłając e - mail na adres: iod@totalizator.pl, a&nbsp;także za pośrednictwem poczty
                tradycyjnej, pod wskazanym powyżej adresem siedziby Administratora.</p>
            <h3>2. Inspektor danych</h3>
            <p>Administrator wyznaczył osobę odpowiedzialną za ochronę danych osobowych, tj. Inspektora Ochrony Danych,
                z&nbsp;
                którym kontakt jest możliwy za pośrednictwem adresu mailowego: iod@totalizator.pl oraz za pośrednictwem
                poczty tradycyjnej, pod wskazanym powyżej adresem siedziby Administratora z&nbsp;dopiskiem „Do Inspektora
                Ochrony
                Danych”.</p>
            <h3>3. Cele i podstawy przetwarzania</h3>
            <p>Dane osobowe będą przetwarzane w następujących celach: </p>
            <ul class="no-list">
                <li>a) przeprowadzenia Loterii, w&nbsp;szczególności przeprowadzenia procedury weryfikacji oraz wydania
                    nagród i&nbsp;
                    ogłoszenia wyników oraz realizacji praw związanych z ewentualną reklamacją - podstawą prawną
                    przetwarzania jest niezbędność do wypełnienia obowiązku prawnego ciążącego na Administratorze (art. 6
                    ust. 1 lit. c RODO), wynikająca w szczególności z&nbsp;przepisów ustawy z&nbsp;dnia 19 listopada 2009 r.
                    o&nbsp;grach
                    hazardowych i&nbsp;rozporządzenia wykonawczego do tej ustawy oraz Ustawy z dnia 1 marca 2018 r. o&nbsp;
                    przeciwdziałaniu praniu pieniędzy oraz finansowaniu terroryzmu,</li>
                <li>b) zrealizowania żądania wydania imiennego zaświadczenia o&nbsp;uzyskanej wygranej oraz w&nbsp;celu
                    ewidencji
                    wydanych w&nbsp;Loterii nagród, których wartość wynosi co najmniej 2.280 złotych - podstawą prawną
                    przetwarzania jest niezbędność do wypełnienia obowiązku prawnego ciążącego na Administratorze (art. 6
                    ust. 1 lit. c RODO) wynikającego w&nbsp;szczególności z&nbsp;przepisów ustawy z&nbsp;dnia 19 listopada
                    2009 r. o&nbsp;grach
                    hazardowych i&nbsp;rozporządzenia wykonawczego do tej ustawy, </li>
                <li>c) umożliwienia założenia konta w&nbsp;Serwisie w&nbsp;ramach świadczenia usług drogą elektroniczną na
                    podstawie
                    (art. 6 ust. 1 lit. b RODO),</li>
                <li>d) ewentualnego ustalenia, dochodzenia lub obrony przed roszczeniami - podstawą prawną przetwarzania
                    danych jest niezbędność przetwarzania do realizacji prawnie uzasadnionego interesu Administratora,
                    rozumianego w&nbsp;tym przypadku jako możliwość ustalenia, dochodzenia lub obrony przed roszczeniami
                    (art. 6
                    ust. 1 lit. f RODO),</li>
                <li>e) w celach analitycznych i&nbsp;statystycznych - podstawą prawną przetwarzania jest prawną
                    przetwarzania
                    jest uzasadniony interes Administratora rozumianego w&nbsp;tym przypadku jako działania podejmowane
                    przez
                    Administratora zmierzające do stałego rozwoju serwisu (art. 6 ust. 1 lit. f RODO),</li>
                <li>f) w&nbsp;przypadku wyrażenia zgody - w&nbsp;celu otrzymywania od Totalizatora Sportowego Sp. z o.o.
                    informacji
                    handlowej i&nbsp;marketingowej za pomocą środków komunikacji elektronicznej (art. 6. ust. 1 lit a RODO).
                </li>
            </ul>
            <p>Zgodę na otrzymywania marketingu od Totalizatora Sportowego możesz wycofać w&nbsp;dowolnym momencie.
                Cofnięcie
                zgody następuje poprzez złożenie przez Uczestnika Loterii oświadczenia w zakresie cofnięcia zgody na
                przetwarzanie danych osobowych w&nbsp;celu marketingowym, dokonywane drogą elektroniczną poprzez wysłanie
                wiadomości e-mail na adres: iod@totalizator.pl.</p>
            <h3>4. Odbiorcy danych</h3>
            <p>Dane osobowe, mogą być udostępnione podmiotom upoważnionym na podstawie przepisów powszechnie obowiązującego
                prawa w&nbsp;szczególności instytucjom uprawnionym do kontroli działalności Administratora lub instytucjom
                uprawnionym do uzyskania danych osobowych na podstawie przepisów prawa. Dane osobowe mogą być przetwarzane
                przez inne podmioty na podstawie zawartych z&nbsp;Administratorem umów powierzenia przetwarzania danych
                osobowych.</p>
            <h3>5. Okres przechowywania danych</h3>
            <p>Dane osobowe Uczestników Loterii, będą przetwarzane:</p>
            <ul class="no-list">
                <li>a) co do zasady przez czas trwania Loterii określony w&nbsp;Regulaminie oraz dodatkowo przez okres 6
                    miesięcy
                    po zakończeniu Loterii, jako czas niezbędny do obsługi roszczeń w&nbsp;związku z art. 21 ustawy
                    o&nbsp;grach
                    hazardowych albo</li>
                <li>b) do momentu realizacji prawnie uzasadnionego interesu albo </li>
                <li>c) do momentu wygaśnięcia obowiązku przechowywania danych osobowych wynikającego z&nbsp;przepisów
                    powszechnie
                    obowiązującego prawa albo </li>
                <li>d) do czasu wycofania zgody w&nbsp;przypadku udzielenia zgody na cele marketingowe i&nbsp;handlowe</li>
            </ul>
            <h3>6. Prawa osób, których dane dotyczą</h3>
            <p>W związku z&nbsp;przetwarzaniem danych osobowych osobie, której dane dotyczą przysługują następujące prawa
                do:
                dostępu do danych oraz otrzymania ich kopii, sprostowania danych, usunięcia danych, ograniczenia
                przetwarzania danych, prawo do sprzeciwu wobec przetwarzania danych, prawo do przenoszenia danych. Prawa te
                przysługują w przypadkach i&nbsp;w&nbsp;zakresie przewidzianym przez obowiązujące przepisy prawa.
                W&nbsp;związku z&nbsp;
                przetwarzaniem danych przysługuje również prawo do wniesienia skargi do organu nadzorczego - Prezesa Urzędu
                Ochrony Danych Osobowych. </p>
            <h3>7. Informacja o wymogu/dobrowolności podania danych</h3>
            <p>Podanie danych jest dobrowolne, jednak ze względu na wymóg ustawowy ich podanie jest niezbędne do wzięcia
                udziału w Loterii.</p>
            <h3>8. Informacja o&nbsp;zautomatyzowanym podejmowaniu decyzji, w&nbsp;tym profilowaniu</h3>
            <p>W oparciu o&nbsp;dane osobowe, Administrator nie będzie podejmował zautomatyzowanych decyzji, w&nbsp;tym
                decyzji
                będących wynikiem profilowania.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "klauzula",
    data: () => ({}),
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {},
    watch: {},
    components: {}
};
</script>
