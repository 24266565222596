var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"forms"},[_c('ValidationObserver',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"rules":"required|email","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var classes = ref.classes;
return [_c('div',{staticClass:"form-group",class:classes},[_c('label',[_vm._v("e-mail podany podczas rejestracji")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.loginForm.f_email),expression:"loginForm.f_email"}],attrs:{"type":"email","autocomplete":"off"},domProps:{"value":(_vm.loginForm.f_email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.loginForm, "f_email", $event.target.value)}}}),_vm._v(" "),(failedRules.required)?_c('span',{staticClass:"error"},[_vm._v("Pole email jest wymagane")]):_vm._e(),_vm._v(" "),(failedRules.email)?_c('span',{staticClass:"error"},[_vm._v("Wprowadź poprawny adres e-mail")]):_vm._e()])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":{ required: true },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var classes = ref.classes;
return [_c('div',{staticClass:"form-group",class:classes},[_c('label',[_vm._v("hasło")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.loginForm.f_pass),expression:"loginForm.f_pass"}],attrs:{"type":"password","autocomplete":"off"},domProps:{"value":(_vm.loginForm.f_pass)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.loginForm, "f_pass", $event.target.value)}}}),_vm._v(" "),(failedRules.required)?_c('span',{staticClass:"error"},[_vm._v("Pole hasło jest wymagane")]):_vm._e()])]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"form-group clearfix"},[_c('div',{staticClass:"col-md-12",staticStyle:{"text-align":"right","padding-top":"10px"}},[_c('Button',{attrs:{"title":'Zaloguj',"loading":_vm.loading,"cssClass":'pull-right'}})],1)])],1)]}}])}),_vm._v(" "),_c('div',{staticClass:"form-group clearfix"},[_c('div',{staticClass:"row clearfix"},[_c('div',{staticClass:"col-md-12",staticStyle:{"text-align":"center","margin-right":"10px","margin-top":"20px"}},[_c('router-link',{staticClass:"button",staticStyle:{"margin-right":"20px"},attrs:{"tag":"button","to":"/przypomnienie-hasla"}},[_c('span',[_vm._v("Zmień hasło")])])],1)])])],1)]),_vm._v(" "),_c('Notification')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-title"},[_c('span',{staticClass:"left"}),_vm._v(" "),_c('span',{staticClass:"text"},[_vm._v("Logowanie")]),_vm._v(" "),_c('span',{staticClass:"right"})])}]

export { render, staticRenderFns }