<template>
  <div class="passreminder">
    <div class="section-title">
      <span class="left"></span>
      <span class="text">Zasady Loterii</span>
      <span class="right"></span>
    </div>
    <div class="page-content">
      <div class="forms">
        <ValidationObserver ref="registerForm" aria-autocomplete="false" tag="form" @submit.prevent="onSubmit">
          <ValidationProvider rules="required|passwordStrength" v-slot="{ failedRules, classes }" name="confirm">
            <div class="form-group" :class="classes">
              <label>Hasło *</label>
              <input type="password" v-model="passwordReset.f_password" name="f_pass1" autocomplete="off" />
              <span v-if="failedRules.required" class="error">Pole hasło jest wymagane.</span>
              <span v-if="failedRules.passwordStrength" class="error">Hasło musi zawierać 8 znaków, w tym jeden duży
                znak.</span>
            </div>
          </ValidationProvider>
          <ValidationProvider rules="required|password:@confirm|passwordStrength" v-slot="{ failedRules, classes }">
            <div class="form-group" :class="classes">
              <label>Powtórz hasło *</label>
              <input type="password" v-model="passwordReset.f_password2" name="f_pass2" autocomplete="off" />
              <span v-if="failedRules.required" class="error">Pole hasło jest wymagane.</span>
              <span v-if="failedRules.passwordStrength" class="error">Hasło musi zawierać 8 znaków, w tym jeden duży
                znak.</span>
              <span v-if="failedRules.password" class="error">Hasła nie są takie same.</span>
            </div>
          </ValidationProvider>
          <div class="row clearfix">
            <div class="col-md-12" style="text-align:right;">
              <Button :title="'Zatwierdź'" :loading="submitted" :cssClass="'pull-right'" />
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
    <Notification />
  </div>
</template>

<script>
import axios from "axios";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import pl from "vee-validate/dist/locale/pl";

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Powtórz hasło",
});

extend("passwordStrength", (value) => {
  var mediumRegex = new RegExp(
    "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,20})"
  );
  if (mediumRegex.test(value)) {
    return true;
  }
  return "8 znaków";
});

for (let rule in rules) {
  extend(rule, {
    ...rules[rule],
    message: pl.messages[rule],
  });
}

export default {
  name: "resethasla",
  data: () => ({
    passwordReset: {
      f_password: "",
      f_password2: "",
      token: ""
    },
    submitted: false,
  }),
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.registerForm.validate();
      if (isValid) {
        this.submitted = true;
        this.passwordReset.token = this.$route.params.hash;

        axios
          .post("passwordChange", this.passwordReset)
          .then((response) => {
            this.$store.state.notification.state = true;
            this.$store.state.notification.message = response.data.message;
            this.$store.state.notification.status = response.data.status;
            this.submitted = false;
            setTimeout(() => {
              this.$router.push({ name: "logowanie" });
            }, 2000);
          })
          .catch((e) => {
            this.submitted = false;
          });
      }
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
};
</script>
