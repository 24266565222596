<template>
  <div v-once class="regulamin">
    <div class="section-title">
      <span class="left"></span>
      <span class="text">Kupon</span>
      <span class="right"></span>
    </div>
    <div class="page-content" style="text-align: center; padding: 80px 0;">
      <img src="/assets/img/Kupon.png" alt="Kupon" />
    </div>
  </div>
</template>

<script>
export default {
  name: "faq",
  data: () => ({}),
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {},
  watch: {},
  components: {},
};
</script>