<template>
  <div class="content passreminder">
    <div class="section-title">
      <span class="left"></span>
      <span class="text">Zmiana hasła</span>
      <span class="right"></span>
    </div>
    <div class="page-content">
      <div class="forms">
        <ValidationObserver ref="registerForm" aria-autocomplete="false" tag="form" @submit.prevent="onSubmit">
          <ValidationProvider :rules="{ required: true }" v-slot="{ errors, classes }" name=" " slim>
            <div class="form-group" :class="classes">
              <label>Adres e-mail *</label>
              <input type="email" v-model="email" name="f_email" autocomplete="off" />
              <span class="error">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
          <div class="row clearfix">
            <div class="col-md-12" style="padding: 20px; text-align: right;">
              <Button :title="'Wyślij'" :loading="submitted" :cssClass="'pull-right'" />
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
    <Notification />
  </div>
</template>

<script>
import axios from "axios";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import pl from "vee-validate/dist/locale/pl";

for (let rule in rules) {
  extend(rule, {
    ...rules[rule],
    message: pl.messages[rule],
  });
}

export default {
  name: "przypomnieniehasla",
  data: () => ({
    email: "",
    submitted: false,
  }),
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.registerForm.validate();
      if (isValid) {
        this.submitted = true;
        axios
          .post("/passwordRestore", { f_email: this.email })
          .then((response) => {
            this.$store.state.notification.state = true;
            this.$store.state.notification.message = response.data.message;
            this.$store.state.notification.status = response.data.status;
            this.submitted = false;
          })
          .catch((e) => {
            this.submitted = false;
          });
      }
    },
    close() {
      this.notificationState = !this.notificationState;
    },
  },
  watch: {},
  components: {
    ValidationObserver,
    ValidationProvider,
  },
};
</script>
