<template>
  <div v-once class="regulamin">
    <div class="section-title">
      <span class="left"></span>
      <span class="text">Polityka prywatności</span>
      <span class="right"></span>
    </div>
    <div class="page-content">
      <p>
        Drogi Uczestniku loterii, sprawdź w jaki sposób Totalizator Sportowy Sp. z o.o., czyli administrator Twoich danych
        osobowych, zbiera je i przetwarza. Pamiętaj o prawach jakie Ci przysługują.
      </p>
      <h3>
        Kto jest administratorem danych osobowych?
      </h3>
      <p>
        Administratorem danych osobowych jest: Totalizator Sportowy Sp. z o.o. z siedzibą w Warszawie przy ul. Targowej
        25, 03-728 Warszawa (dalej „<strong>Administrator</strong>”).
      </p>
      <h3>
        Jak można się z nami skontaktować?
      </h3>
      <p>
        Za kontakt we wszystkich sprawach dotyczących przetwarzania danych osobowych odpowiedzialny jest nasz Inspektor
        Ochrony Danych:
        e-mail: <a href="maitlo:iod@totalizator.pl ">iod@totalizator.pl</a><br />
        adres korespondencyjny: 03-728 Warszawa, ul. Targowa 25.
      </p>
      <h3>Podstawa prawna.</h3>
      <p>
        Od dnia 25 maja 2018 obowiązuje RODO czyli Rozporządzenie o Ochronie Danych Osobowych.<br />
        Jest to rozporządzenie Parlamentu Europejskiego i&nbsp;Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r.<br />
        w&nbsp;sprawie ochrony osób fizycznych w&nbsp;związku z&nbsp;przetwarzaniem danych osobowych i&nbsp;w&nbsp;sprawie
        swobodnego przepływu
        takich danych. Nakłada ono na nas obowiązek jasnego i&nbsp;zrozumiałego poinformowania o&nbsp;naszej polityce
        zbierania
        i&nbsp;przetwarzania danych osobowych.
      </p>
      <h3>
        Skąd mamy Twoje dane?
      </h3>
      <p>
        Dane otrzymaliśmy od Ciebie podczas wypełniania formularza elektronicznego lub oświadczenia papierowego
        w&nbsp;celu
        wydania nagród i&nbsp;ogłoszenia wyników loterii.
      </p>
      <h3>
        Czy muszę podać dane?
      </h3>
      <p>
        Podanie danych jest dobrowolne, jednak ze względu na wymogi ustawowe którym podlegamy jako Organizator, ich
        podanie jest niezbędne do wzięcia udziału w loterii i&nbsp;otrzymania nagrody.
      </p>
      <h3>
        W jakim celu i na jakiej podstawie przetwarzamy dane?
      </h3>
      <p>
        Twoje dane osobowe (w tym adres IP lub inne identyfikatory oraz informacje gromadzone za pośrednictwem plików
        cookies lub innych podobnych technologii) przetwarzamy w&nbsp;celu oraz na podstawie następujący przepisów prawa:
      <ul>
        <li>przeprowadzenia Loterii, w&nbsp;szczególności przeprowadzenia procedury weryfikacji oraz wydania nagród
          i&nbsp;ogłoszenia
          wyników oraz realizacji praw związanych z&nbsp;ewentualną reklamacją - podstawą prawną przetwarzania jest
          niezbędność do wypełnienia obowiązku prawnego ciążącego na Administratorze (art. 6 ust. 1 lit. c RODO),
          wynikająca w&nbsp;szczególności z&nbsp;przepisów ustawy z dnia 19 listopada 2009 r. o grach hazardowych
          i&nbsp;rozporządzenia
          wykonawczego do tej ustawy oraz Ustawy z&nbsp;dnia 1 marca 2018 r. o przeciwdziałaniu praniu pieniędzy oraz
          finansowaniu terroryzmu,</li>
        <li>zrealizowania żądania wydania imiennego zaświadczenia o uzyskanej wygranej oraz w celu ewidencji wydanych w
          Loterii nagród, których wartość wynosi co najmniej 2.280 złotych - podstawą prawną przetwarzania jest
          niezbędność do wypełnienia obowiązku prawnego ciążącego na Administratorze (art. 6 ust. 1 lit. c RODO)
          wynikającego w szczególności z przepisów ustawy z dnia 19 listopada 2009 r. o grach hazardowych i rozporządzenia
          wykonawczego do tej ustawy,</li>
        <li>
          umożliwienia założenia konta w Serwisie w ramach świadczenia usług drogą elektroniczną na podstawie (art. 6 ust.
          1 lit. b RODO),
        </li>
        <li>
          ewentualnego ustalenia, dochodzenia lub obrony przed roszczeniami - podstawą prawną przetwarzania danych jest
          niezbędność przetwarzania do realizacji prawnie uzasadnionego interesu Administratora, rozumianego w tym
          przypadku jako możliwość ustalenia, dochodzenia lub obrony przed roszczeniami (art. 6 ust. 1 lit. f RODO),
        </li>
        <li>
          w celach analitycznych i statystycznych - podstawą prawną przetwarzania jest prawną przetwarzania jest
          uzasadniony interes Administratora rozumianego w tym przypadku jako działania podejmowane przez Administratora
          zmierzające do stałego rozwoju serwisu (art. 6 ust. 1 lit. f RODO),
        </li>
        <li>
          w przypadku wyrażenia zgody – w celu otrzymywania od Totalizatora Sportowego Sp. z o.o. informacji handlowej i
          marketingowej za pośrednictwem środków komunikacji elektronicznej
          (art. 6. ust. 1 lit a RODO).
        </li>
      </ul>
      </p>
      <h3>
        Jak chronimy Twoje dane?
      </h3>
      <p>
        Zapewniamy, że traktujemy dane osobowe ze szczególną starannością i przykładamy dużą wagę do ochrony Twojej
        prywatności.
      </p>
      <p>Uwzględniając stan wiedzy technicznej, koszt wdrażania oraz charakter, zakres, kontekst i cele przetwarzania oraz
        ryzyko naruszenia praw lub wolności osób fizycznych o różnym prawdopodobieństwie wystąpienia i wadze wynikające z
        przetwarzania, Administrator - zarówno przy określaniu sposobów przetwarzania, jak i w czasie samego przetwarzania
        - wdraża odpowiednie środki techniczne i organizacyjne, takie jak pseudonimizacja, zaprojektowane w celu
        skutecznej realizacji zasad ochrony danych, takich jak minimalizacja danych, oraz w celu nadania przetwarzaniu
        niezbędnych zabezpieczeń, tak by spełnić wymogi niniejszego rozporządzenia oraz chronić Twoje prawa. </p>
      <p>
        Administrator wdraża odpowiednie środki techniczne i organizacyjne, aby domyślnie przetwarzane były wyłącznie te
        dane osobowe, które są niezbędne dla osiągnięcia każdego konkretnego celu przetwarzania. Obowiązek ten odnosi się
        do ilości zbieranych danych osobowych, zakresu ich przetwarzania, okresu ich przechowywania oraz ich dostępności.
        W szczególności środki te zapewniają, by domyślnie dane osobowe nie były udostępniane bez interwencji danej osoby
        nieokreślonej liczbie osób fizycznych.
      </p>
      <h3>
        Komu mogą zostać przekazane Twoje dane?
      </h3>
      <p>
        Dane osobowe, mogą być udostępnione podmiotom upoważnionym na podstawie przepisów powszechnie obowiązującego prawa
        w szczególności instytucjom uprawnionym do kontroli działalności Administratora lub instytucjom uprawnionym do
        uzyskania danych osobowych na podstawie przepisów prawa. Dane osobowe mogą być przetwarzane przez inne podmioty na
        podstawie zawartych Dane osobowe mogą być przetwarzane przez inne podmioty na podstawie zawartych z
        Administratorem umów powierzenia przetwarzania danych osobowych.
      </p>
      <h3>
        Ile czasu będziemy przetwarzać dane?
      </h3>
      <p>
        Okres przetwarzania danych przez Administratora zależy od celu przetwarzania. Co do zasady, dane osobowe
        przetwarzane są przez czas trwania Loterii oraz dodatkowo przez okres 6 miesięcy po zakończeniu Loterii, jako czas
        niezbędny do obsługi roszczeń w&nbsp;związku z art. 21 ustawy o&nbsp;grach hazardowych albo. Dane osobowe
        Zwycięzców Loterii
        (w&nbsp;postaci oświadczeń, potwierdzeń odbioru nagrody, ewidencji wysokich wygranych i&nbsp;imiennych zaświadczeń
        o&nbsp;uzyskanej
        wygranej) będą przechowywane przez okres 5 lat licząc od końca roku kalendarzowego,
        w&nbsp;którym nastąpiło wydanie wygranej. Dokumentacja związana ze złożeniem reklamacji będzie przetwarzana
        (przechowywana) przez okres 5 lat licząc od końca roku kalendarzowego, w&nbsp;którym nastąpiło złożenie
        reklamacji.
      </p>
      <h3>
        Czy przekazujemy Twoje dane do państw spoza Europejskiego Obszaru Gospodarczego?
      </h3>
      <p>
        Nie. Twoje dane osobowe przechowujemy wyłącznie na terenie EOG (Europejski Obszar Gospodarczy).
      </p>
      <h3>
        Jakie masz prawa w związku z przetwarzaniem danych?
      </h3>
      <p>
        Podając dobrowolnie swoje dane masz prawo wglądu do ich treści, żądania ich poprawiania, sprostowania, usunięcia,
        ograniczenia ich przetwarzania, zgłoszenia sprzeciwu wobec
        ich przetwarzania, żądania ich przeniesienia, a&nbsp;także do cofnięcia zgody na ich przetwarzanie
        w&nbsp;dowolnym momencie, co pozostanie bez wpływu na zgodność z&nbsp;prawem ich przetwarzania, którego dokonano
        na
        podstawie zgody przed jej cofnięciem.
      </p>
      <p>
        Zgodę na otrzymywania marketingu od Totalizatora Sportowego możesz wycofać w&nbsp;dowolnym momencie. Cofnięcie
        zgody
        następuje poprzez złożenie przez Uczestnika Loterii oświadczenia
        w&nbsp;zakresie cofnięcia zgody na przetwarzanie danych osobowych w&nbsp;celu marketingowy, dokonywane drogą
        elektroniczną
        poprzez wysłanie wiadomości e-mail na adres: iod@totalizator.pl.
      </p>
      <p>
        Pamiętaj jednak, że odmowa podania danych lub ograniczenie ich przetwarzania może uniemożliwić udział
        w&nbsp;loterii
        lub wydanie nagród.
      </p>
      <p>
        Pamiętaj, że masz także prawo wniesienia skargi do organu nadzoru sprawującego kontrolę nad przestrzeganiem
        przepisów o ochronie danych osobowych.
      </p>
      <h3>
        Powiadomienia bezpośrednio na Twoją przeglądarkę internetową.
      </h3>
      <p>
        Nasza strona zapewnia dodatkową funkcjonalność w postaci możliwości otrzymywania przez jej użytkownika komunikatów
        w formie powiadomień. Są nimi małe prostokątne wiadomości, wysyłane przez stronę internetową za Twoją zgodą.
        Funkcjonalność nie wiąże się z koniecznością podawania danych osobowych.
      </p>
      <p>
        Pierwsze uruchomienie przeglądarki internetowej spowoduje wyświetlenie się komunikatu o treści : Czy chcesz być na
        bieżąco z Loterią Świąteczną oraz jej wynikami? Pamiętaj, możesz ale nie musisz wyrazić zgody na otrzymywanie
        powiadomień. Komunikat możesz też zignorować. Jeżeli zgodzisz się na ich otrzymywanie będą one przesyłane
        bezpośrednio na Twoją przeglądarkę internetową. Będziemy wtedy przekazywać Ci informacje dotyczące wyników
        losowań.
      </p>
      <p>
        Aby zrezygnować z subskrypcji, wystarczy zmienić ustawienia w przeglądarce internetowej.
      </p>

      <h2>
        POLITYKA PLIKÓW COOKIES
      </h2>
      <h3>
        Co to są pliki cookies?
      </h3>
      <p>
        Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane
        są w urządzeniu końcowym użytkownika serwisu i przeznaczone są do korzystania
        ze stron internetowych naszego serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej,
        z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.
      </p>
      <h3>
        Jakiego rodzaju pliki cookies są wykorzystywane przez Serwis?
      </h3>
      <p>
        Serwis nie zbiera w sposób automatyczny żadnych informacji, z wyjątkiem informacji zawartych
        w plikach cookies. Podmiotem zamieszczającym na urządzeniu końcowym użytkownika serwisu pliki cookies oraz
        uzyskującym do nich dostęp jest Totalizator Sportowy Sp. z o.o. z siedzibą w Warszawie ul. Targowa 25, 03-728.
      </p>
      <p>
        Stosowane są dwa rodzaje plików „cookies” – „sesyjne” oraz „stałe”. Pierwsze z nich są plikami tymczasowymi, które
        pozostają na urządzeniu użytkownika, aż do wylogowania ze strony internetowej lub wyłączenia oprogramowania
        (przeglądarki internetowej). „Stałe” pliki pozostają na urządzeniu użytkownika przez czas określony w parametrach
        plików „cookies” albo do momentu ich ręcznego usunięcia przez użytkownika.
      </p>
      <p>
        Oprócz własnych plików cookies w serwisie zamieszczane i wykorzystywane mogą być pliki cookies stron trzecich,
        należące w szczególności do reklamodawców, operatorów systemów adserwerowych oraz operatorów systemów
        analizujących i raportujących oglądalność.
      </p>
      <p>
        Pliki „cookies” wykorzystywane przez partnerów operatora strony internetowej, w tym w szczególności użytkowników
        strony internetowej, podlegają ich własnej polityce prywatności.
      </p>
      <h3>
        W jakim celu wykorzystywane są pliki cookies?
      </h3>
      <p>
        Pliki „cookies” Totalizatora Sportowego Sp. z o.o. są wykorzystywane w celu:
      <ul>
        <li>dostosowania zawartości stron internetowych do preferencji użytkownika oraz optymalizacji korzystania ze stron
          internetowych.</li>
        <li>tworzenia anonimowych, zagregowanych statystyk, które pomagają zrozumieć w jaki sposób użytkownik korzysta ze
          stron internetowych co umożliwia ulepszanie ich struktury i zawartości, z wyłączeniem personalnej identyfikacji
          użytkownika.</li>
      </ul>
      Pliki „cookies” stron trzecich, są wykorzystywane w następujących celach:
      <ul>
        <li>zbieranie danych statystycznych o popularności oraz użyteczności stron lub jej elementów.</li>
        <li>Zbieranie danych statystycznych o użytkownikach np. wiek, płeć, informacje demograficzne, zainteresowania
          użytkowników za pomocą kodu Google Analytics (więcej informacji można znaleźć na stronie:
          https://support.google.com/analytics#topic=1008008).</li>
        <li>Dopasowanie treści reklam emitowanych w sieci reklamowej Google (remarketing) – więcej informacji o plikach
          cookies wykorzystywanych w sieci reklamowej Google można znaleźć
          na stronie: http://support.google.com/adwords/answer/2407785.</li>
        <li>Mierzenie skuteczności (śledzenie konwersji) prowadzonych akcji, np. w sieci reklamowej Google, programach
          partnerskich, sieciach afiliacyjnych.</li>
        <li>Zapamiętywanie braku zgody na wyświetlanie w przyszłości niektórych treści.</li>
      </ul>
      </p>
      <h3>
        Google Analitycs
      </h3>
      <p>
        Stosowane funkcje reklamowe Google Analytics w naszym serwisie obejmują:
      <ul>
        <li>a) remarketing w Google Analytics,</li>
        <li>b) raporty wyświetleń w sieci reklamowej Google,</li>
        <li>c) raporty zainteresowań i danych demograficznych Google Analytics,</li>
        <li>d) zintegrowane usługi, które wymagają od Google Analytics zbierania danych za pośrednictwem plików cookie
          dotyczących reklam i anonimowych identyfikatorów.</li>
      </ul>
      </p>
      <p>
        Zbierane są informacje o sposobie przeglądania strony, popularności poszczególnych podstron oraz dane służące do
        personalizacji reklam np. wiek, płeć czy zainteresowania użytkownika.
        Na ich podstawie tworzone są raporty zainteresowań oraz danych demograficznych. Raporty
        te w formie statystycznej mogą być upubliczniane oraz udostępniane partnerom biznesowym firmy. Na podstawie tych
        danych oraz raportów nie jest możliwe zidentyfikowanie poszczególnych użytkowników.
      </p>
      <p>
        W każdej chwili można wyłączyć funkcje reklamowe Google Analytics wykorzystując Ads Settings, narzędzia
        przeznaczone do blokowania funkcji Google Analytics dostępne pod adresem https://tools.google.com/dlpage/gaoptout/
        .
      </p>
      <h3>
        Pliki cookies można podzielić na:
      </h3>
      <p>
        „niezbędne” pliki cookies, umożliwiające korzystanie z usług dostępnych w ramach Serwisu,
        np. uwierzytelniające pliki cookies wykorzystywane do usług wymagających uwierzytelniania w ramach Serwisu; pliki
        cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie
        uwierzytelniania w ramach Serwisu;
      </p>
      <p>
        „wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych
        Serwisu; „funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych przez Użytkownika ustawień i
        personalizację interfejsu Użytkownika, np. w zakresie wybranego języka lub regionu, z którego pochodzi Użytkownik,
        rozmiaru czcionki, wyglądu strony internetowej itp.
      </p>
      <h3>
        Ustawienia plików cookies
      </h3>
      <p>
        Standardowo oprogramowanie służące do przeglądania stron internetowych domyślnie dopuszcza umieszczanie plików
        „cookies” na urządzeniu końcowym Użytkownika. Ustawienia te mogą zostać zmienione przez Użytkownika w taki sposób,
        aby blokować automatyczną obsługę plików „cookies”
        w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym przesłaniu
        na urządzenie użytkownika. Szczegółowe informacje o możliwości i sposobach obsługi plików „cookies” dostępne są w
        ustawieniach oprogramowania (przeglądarki internetowej). W przypadku, gdy Użytkownik nie dokona zmiany domyślnych
        ustawień przeglądarki internetowej w zakresie ustawień cookies, pliki te będą zamieszczone w urządzeniu końcowym
        Użytkownika. Wyłączenie lub zmiana domyślnych ustawień plików cookies może spowodować utrudnienia w korzystaniu z
        niektórych naszych stron i serwisów internetowych, w tym z Serwisu.
      </p>
      <h3>
        Ustawienia przechowywania plików cookies w przeglądarkach:
      </h3>
      <ul>
        <li>Internet Explorer</li>
        <li>Edge</li>
        <li>Firefox</li>
        <li>Opera</li>
        <li>Chrome</li>
        <li>Safari</li>
      </ul>
      <p>
      <div class="table">
        <div class="head">
          <div class="name">Nazwa</div>
          <div class="desc">Cel</div>
          <div class="time">Okres przechowywania</div>
        </div>
        <div class="data">
          <div class="name">_hjSession_*</div>
          <div class="desc">Zapewnienie funkcjonalności strony internetowej</div>
          <div class="time">Na czas trwania sesji</div>
        </div>
      </div>
      </p>

      <p>
        Marketingowe pliki cookies - służą do publikowania reklam dostosowanych personalnie do preferencji użytkownika
        oraz monitorowania tego, jak często odwiedzane są poszczególne elementy strony internetowej.
      <div class="table">
        <div class="head">
          <div class="name">Nazwa</div>
          <div class="desc">Cel</div>
          <div class="time">Okres przechowywania</div>
        </div>
        <div class="data">
          <div class="name">_clck</div>
          <div class="desc">Przechowywanie numeru identyfikacyjnego użytkownika </div>
          <div class="time">1 rok</div>
        </div>
      </div>
      </p>

      <p>
        Statystyczne pliki cookies - wykorzystywane są do monitorowania działania witryny internetowej, na przykład w celu
        poznania liczby odwiedzanych stron lub liczby użytkowników, którzy obejrzeli daną sekcję.
      <div class="table">
        <div class="head">
          <div class="name">Nazwa</div>
          <div class="desc">Cel</div>
          <div class="time">Okres przechowywania</div>
        </div>
        <div class="data">
          <div class="name">_clsk</div>
          <div class="desc">Zliczanie i przechowywanie wyświetleń stron internetowych przez jednego użytkownika w ciągu
            pojedynczej
            sesji</div>
          <div class="time">1 dzień</div>
        </div>
        <div class="data">
          <div class="name">_ga_*</div>
          <div class="desc">Zliczane i przechowywanie wyświetleń strony internetowej </div>
          <div class="time">2 lata</div>
        </div>
        <div class="data">
          <div class="name">_gid</div>
          <div class="desc">Zliczanie i przechowywanie liczby wyświetleń strony internetowej</div>
          <div class="time">1 dzień</div>
        </div>
        <div class="data">
          <div class="name">_hjSessionUser_*</div>
          <div class="desc">Przechowywanie numeru identyfikacyjnego użytkownika</div>
          <div class="time">1 rok</div>
        </div>
        <div class="data">
          <div class="name">_fbp</div>
          <div class="desc">Śledzenie i przechowywanie odwiedzin strony internetowej</div>
          <div class="time">3 miesiące</div>
        </div>
        <div class="data">
          <div class="name">_gcl_au</div>
          <div class="desc">Śledzenie i przechowywanie konwersji stron internetowych</div>
          <div class="time">Permanentny</div>
        </div>
      </div>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "faq",
  data: () => ({}),
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {},
  watch: {},
  components: {},
};
</script>