<template>
    <div class="statement">
        <div class="content">
            <ValidationObserver ref="registerForm" aria-autocomplete="false" tag="form" @submit.prevent="onSubmit">
                <div class="row clearfix">
                    <div class="col-md-4">
                        <ValidationProvider :rules="{ required: true, min: 13 }" v-slot="{ failedRules, classes }">
                            <div class="form-group">
                                <label for="code">Tu wpisz zwycięski kod:</label>
                                <input class="code" name="code" maxlength="13" v-model="user.Code" v-mask="'NNNN-NNN-NNNN'"
                                    type="text" :class="classes" />
                                <span class="error">
                                    <span v-if="failedRules.required">Prawidłowy kod ma 13 znaków</span>
                                    <span v-if="failedRules.min">Prawidłowy kod ma 13 znaków</span>
                                </span>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-md-8">
                        <ol class="chb-list statement-type">
                            <ValidationProvider :rules="{ required: { allowFalse: false } }" name=" " tag="li">
                                <input type="radio" v-model="user.Statement" name="statement" value="1" />
                                POSIADAM KUPON Z KODEM PROMOCYJNYM
                            </ValidationProvider>
                            <ValidationProvider :rules="{ required: { allowFalse: false } }" name=" " tag="li">
                                <input type="radio" v-model="user.Statement" name="statement" value="2" />
                                POSIADAM KOD ELEKTRONICZNY (wydawany na Konto)
                            </ValidationProvider>
                        </ol>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-md-8">
                        <ValidationProvider :rules="{ required: user.Statement == '1' }" v-slot="{ failedRules, classes }">
                            <div class="form-group">
                                <label for="code">Tu wpisz numer seryjny z dołu Kuponu Promocyjnego (<a target="_blank"
                                        href="/kupon-numer-seryjny">Przykład</a>)</label>
                                <input class="code" name="code" maxlength="21" v-model="user.SerialNumber"
                                    v-mask="'NNNN-NNNNNNNNN-NNNNNN'" type="text" :class="classes" />
                                <span class="error">
                                    <span v-if="failedRules.required">Prawidłowy kod ma 21 znaków</span>
                                </span>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-md-12">
                        <h2 class="text-center">
                            OŚWIADCZENIE<br />dla Laureata Nagrody IV stopnia w postaci:
                        </h2>
                        <p>
                            <strong>
                                nagrody pieniężnej w wysokości 500 złotych, przekazywanej przelewem bankowym przez TS na
                                wskazany rachunek bankowy.
                            </strong>
                        </p>
                        <p>
                            Dziękujemy za udział w Loterii promocyjnej „Lotteria Świąteczna”. Oświadczenie należy wypełnić i
                            <strong>wysłać do Organizatora w terminie do 7 dni kalendarzowych od dnia uzyskania informacji
                                o&nbsp;wygranej</strong>.
                        </p>
                        <p>
                            W przypadku gdy Kod Promocyjny został wydany za zakupy dokonane w&nbsp;Serwisie Internetowym
                            www.lotto.pl lub w&nbsp;Aplikacji LOTTO prosimy o&nbsp;podanie wyłącznie numeru Kodu
                            Promocyjnego tj. bez
                            konieczności wprowadzania numeru seryjnego transakcji.
                        </p>
                        <p>
                            Można skorzystać z niniejszego formularza elektronicznego lub wydrukować załącznik nr 2
                            z&nbsp;Regulaminu Loterii, wypełnić go i&nbsp;przesłać wraz z&nbsp;oryginałem kuponu
                            Promocyjnego na
                            adres
                            siedziby Organizatora (ul. Targowa 25, 03-728, Warszawa).
                        </p>
                        <p>
                            <i>Prosimy o&nbsp;dokładne sprawdzenie wprowadzonych danych. Błędne wypełnienie formularza
                                uniemożliwi przekazanie nagrody.</i>
                        </p>
                        <p>
                            Zgodnie z wymogiem § 14 ust. 9 Regulaminu Loterii promocyjnej „Lotteria Świąteczna”, ja niżej
                            podpisany:
                        </p>
                    </div>
                </div>

                <div class="row clearfix">
                    <div class="col-md-6">
                        <ValidationProvider :rules="{ required: true, min: 2, max: 100, noSpecialCharacters: true }"
                            v-slot="{ failedRules, classes }">
                            <div class="fg">
                                <label>Imię</label>
                                <input v-model="user.Name" type="text" maxlength="100" minlength="2" class="input"
                                    :class="classes" />
                            </div>
                            <div class="error">
                                <span v-if="failedRules.required">Imię jest wymagane.</span>
                                <span v-if="failedRules.min">Imię nie może zawierać mniej niż dwa znaki.</span>
                                <span v-if="failedRules.max">Imię nie może zawierać więcej niż sto znaków.</span>
                                <span v-if="failedRules.noSpecialCharacters">Imię nie może zawierać znaków
                                    specjalnych.</span>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                        <ValidationProvider :rules="{ required: true, min: 2, max: 100, noSpecialCharacters: true }"
                            v-slot="{ failedRules, classes }">
                            <div class="fg">
                                <label>Nazwisko:</label>
                                <input v-model="user.Surname" type="text" maxlength="100" minlength="2" class="input"
                                    :class="classes" />
                            </div>
                            <div class="error">
                                <span v-if="failedRules.required">Nazwisko jest wymagane.</span>
                                <span v-if="failedRules.noSpecialCharacters">Nazwisko nie może zawierać znaków
                                    specjalnych.</span>
                                <span v-if="failedRules.min">Nazwisko nie może zawierać mniej niż cztery znaki.</span>
                                <span v-if="failedRules.max">Nazwisko nie może zawierać więcej niż dwieście znaków.</span>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>

                <div class="row clearfix">
                    <div class="col-md-6">
                        <ValidationProvider :rules="{ required: true, min: 11, max: 11 }" v-slot="{ failedRules, classes }">
                            <div class="fg">
                                <label>Nr. tel. kom (podany podczas rejestracji):</label>
                                <input v-model="user.Phone" type="text" maxlength="11" minlength="11" class="input"
                                    :class="classes" v-mask="'###-###-###'" />
                            </div>
                            <div class="error">
                                <span v-if="failedRules.required">Nr. tel. kom jest wymagany.</span>
                                <span v-if="failedRules.min">Nr. tel. kom jest nie poprawny.</span>
                                <span v-if="failedRules.max">Nr. tel. kom jest nie poprawny.</span>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                        <ValidationProvider :rules="{ required: true, email: true, max: 100 }"
                            v-slot="{ failedRules, classes }">
                            <div class="fg">
                                <label>Adres e-mail (podany podczas rejestracji):</label>
                                <input v-model="user.Email" type="text" maxlength="100" minlength="2" class="input"
                                    :class="classes" />
                            </div>
                            <div class="error">
                                <span v-if="failedRules.required">
                                    Adres e-mail jest wymagany.</span>
                                <span v-if="failedRules.email">Nie poprawny adres e-mail.</span>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-md-12">
                        <ValidationProvider :rules="{ required: true, min: 32, max: 32, ibanValidator: true }"
                            v-slot="{ failedRules, classes }">
                            <div class="fg">
                                <label>Numer rachunku bankowego prowadzonego na terytorium Polski:</label>
                                <input v-model="user.BankAccount" type="text" v-mask="'NN-NNNN-NNNN-NNNN-NNNN-NNNN-NNNN'"
                                    class="input" :class="classes" />
                            </div>
                            <div class="error">
                                <span v-if="failedRules.required">Pole jest wymagane.</span>
                                <span v-if="failedRules.min">Pole nie może zawierać mniej niż trzydzieści dwa
                                    znaki.</span>
                                <span v-if="failedRules.max">Pole nie może zawierać więcej niż trzydzieści dwa
                                    znaki.</span>
                                <span v-if="failedRules.ibanValidator">Niepoprawny numer konta bankowego.</span>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        * Zaznaczenie pola stanowi warunek wydania nagrody.
                        <ul class="chb-group">
                            <ValidationProvider :rules="{ required: { allowFalse: false } }"
                                v-slot="{ failedRules, classes }" slim>
                                <li :class="classes">
                                    <input class="checkbox" v-model="user.consentRegulation" type="checkbox" />
                                    <p>
                                        **Potwierdzam, że zapoznałem/(am) się z treścią <a target="_blank"
                                            href="/assets/pdf/Regulamin-Lotterii-Swiatecznej.pdf">Regulaminu
                                            Loterii</a> promocyjnej
                                        „Lotteria Świąteczna”.
                                    </p>
                                    <div class="error">
                                        <span v-if="failedRules.required">Pole jest wymagane.</span>
                                    </div>
                                </li>
                            </ValidationProvider>

                            <ValidationProvider :rules="{ required: { allowFalse: false } }"
                                v-slot="{ failedRules, classes }" slim>
                                <li :class="classes">
                                    <input class="checkbox" v-model="user.consentExcluded" type="checkbox" />
                                    <p>
                                        **Potwierdzam, że zgodnie z § 8 ust. 2 i ust. 3 Regulaminu Loterii promocyjnej
                                        „Lotteria Świąteczna” nie jestem członkiem organów (Zgromadzenie Wspólników, Rada
                                        Nadzorcza, Zarząd) spółek: Totalizator Sportowy Sp. z o.o. z siedzibą w Warszawie,
                                        IGT Polska Sp. z o.o. z siedzibą w Warszawie, pracownikiem lub współpracownikiem
                                        Totalizator Sportowy Sp. z o.o., Kolektorem ani członkiem najbliższej rodziny
                                        którejkolwiek z ww. osób.
                                    </p>
                                    <div class="error">
                                        <span v-if="failedRules.required">Pole jest wymagane.</span>
                                    </div>
                                </li>
                            </ValidationProvider>

                            <ValidationProvider :rules="{ required: { allowFalse: false } }"
                                v-slot="{ failedRules, classes }" slim>
                                <li :class="classes">
                                    <input class="checkbox" v-model="user.ConsentPersonalData" type="checkbox" />
                                    <p>
                                        <strong>
                                            **Potwierdzam zapoznanie się z klauzulą dotyczącą przetwarzania danych osobowych
                                            przez Totalizator Sportowy Sp. z o.o.
                                        </strong>
                                    </p>
                                    <div class="error">
                                        <span v-if="failedRules.required">Pole jest wymagane.</span>
                                    </div>
                                </li>
                            </ValidationProvider>
                        </ul>
                    </div>
                </div>
                <Clause />
                <div class="row clearfix">
                    <div class="col-md-12">
                        Potwierdzam prawdziwość danych i oświadczeń zawartych w treści
                        niniejszego dokumentu.
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        &nbsp;
                    </div>
                    <div class="col-md-6">
                        <ValidationProvider :rules="{ required: true }" v-slot="{ failedRules, classes }" tag="div">
                            <input v-model="user.Signature" type="text" maxlength="200" minlength="4" class="input"
                                :class="classes" />
                            <div class="text-center">(data)</div>
                            <div class="error">
                                <span v-if="failedRules.required">Pole jest wymagane.</span>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row clearfix">
                    <!-- <div class="col-md-3">
                        &nbsp;
                        <span v-if="user.Statement === '1'">
                            <picture-input ref="pictureInput" @change="onChange" width="200" height="200" margin="16"
                                accept="image/jpeg, image/png" size="10" buttonClass="btn-change" :customStrings="{
                                    upload: 'Załącz skan lub zdjęcie kuponu promocyjnego',
                                    drag: 'Załącz skan lub zdjęcie kuponu promocyjnego',
                                    tap: 'Załącz skan lub zdjęcie kuponu promocyjnego',
                                    change: 'Zmień skan',
                                    aspect: '',
                                    remove: 'Usuń skan',
                                    select: 'Załącz skan lub zdjęcie kuponu promocyjnego',
                                    selected: '',
                                    fileSize: '',
                                    fileType: '',
                                }"></picture-input>
                        </span>
                    </div>
                    <div class="col-md-4 img-status">
                        &nbsp;
                        <div v-if="imageStatus === 'NOIMG'">
                            <span class="no-img">Dodaj skanu kuponu.</span>
                        </div>
                    </div> -->
                    <div class="col-md-12">
                        <Button :title="'Wyślij zgłoszenie'" :loading="submitted" :cssClass="'pull-right'"></Button>
                    </div>
                </div>
            </ValidationObserver>
        </div>
        <Notification />
        <Loader />
    </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import router from "vue-router";
import {
    ValidationObserver,
    ValidationProvider,
    extend
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import pl from "vee-validate/dist/locale/pl";
import "../../helpers/validators";
import PEPInfo from "../../components/public/pepInfo";
import Clause from "../../components/public/clause4";
// import PictureInput from "vue-picture-input";

for (let rule in rules) {
    extend(rule, {
        ...rules[rule],
        message: pl.messages[rule],
    });
}

Vue.use(router);
export default {
    name: "regulamin",
    data: () => ({
        user: {
            Name: "",
            Surname: "",
            City: "",
            PostalCode: "",
            Street: "",
            StreetNumber: "",
            FlatNumber: "",
            Phone: "",
            Email: "",
            Nationality: "",
            CountryOfBirth: "",
            Pesel: "",
            BankAccount: "",
            DocumentNumber: "",
            DocumentType: "",
            ConsentRegulation: false,
            ConsentExcluded: false,
            ConsentAdditionalPayment: false,
            ConsentPersonalData: false,
            PepStatement1: "",
            PepStatement2: "",
            PepStatement3: "",
            StatementType: "Type4",
            Signature1: "",
            Signature2: "",
            ConsentRb: false,
            Code: "",
            SerialNumber: "",
            Statement: "",
            token: ""
        },
        submitted: false,
        // imageStatus: "",
        // imageUpload: false,
    }),
    mounted() {
        window.scrollTo(0, 0);
        this.user.Signature = this.formatDate(new Date());
    },
    methods: {
        async onSubmit() {
            const isValid = await this.$refs.registerForm.validate();
            if (isValid) {
                if (!this.submitted) {
                    this.submitted = true;
                    var token = sessionStorage.getItem("sessionKey");
                    var hash = this.$route.params.hash;
                    if (this.user.Statement == "2") {
                        axios
                            .post(
                                "PostTestimonyIlotto",
                                "Code=" +
                                this.user.Code.toUpperCase().replaceAll('-', '') +
                                "&SerialNumber=" +
                                this.user.SerialNumber +
                                "&Name=" +
                                this.user.Name +
                                "&Surname=" +
                                this.user.Surname +
                                "&Email=" +
                                this.user.Email +
                                "&Phone=" +
                                this.user.Phone +
                                "&StatementType=" +
                                this.user.StatementType +
                                "&City=" +
                                this.user.City +
                                "&PostalCode=" +
                                this.user.PostalCode +
                                "&Street=" +
                                this.user.Street +
                                "&StreetNumber=" +
                                this.user.StreetNumber +
                                "&FlatNumber=" +
                                this.user.FlatNumber +
                                "&ConsentAdditionalPayment=" +
                                this.user.ConsentAdditionalPayment +
                                "&ConsentPersonalData=" +
                                this.user.ConsentPersonalData +
                                "&ConsentExcluded=" +
                                this.user.ConsentExcluded +
                                "&ConsentRb=" +
                                this.user.ConsentRb +
                                "&ConsentRegulation=" +
                                this.user.ConsentRegulation +
                                "&DocumentType=" +
                                this.user.DocumentType +
                                "&DocumentNumber=" +
                                this.user.DocumentNumber +
                                "&Nationality=" +
                                this.user.Nationality +
                                "&CountryOfBirth=" +
                                this.user.CountryOfBirth +
                                "&Statement=" +
                                this.user.Statement +
                                "&PepStatement1=" +
                                this.user.PepStatement1 +
                                "&PepStatement2=" +
                                this.user.PepStatement2 +
                                "&PepStatement3=" +
                                this.user.PepStatement3 +
                                "&Pesel=" +
                                this.user.Pesel +
                                "&BankAccount=" +
                                this.user.BankAccount +
                                "&Signature1=" +
                                this.user.Signature +
                                "&token=" +
                                token +
                                "%hash=" +
                                hash
                            )
                            .then((response) => {
                                this.submitted = false;
                                this.$store.state.notification.state = true;
                                this.$store.state.notification.message = response.data.message;
                                this.$store.state.notification.status = response.data.status;
                            })
                            .catch((error) => {
                                this.submitted = false;
                            });
                    } else {
                        var bodyFormData = new FormData();
                        bodyFormData.append("Name", this.user.Name);
                        bodyFormData.append("Surname", this.user.Surname);
                        bodyFormData.append("City", this.user.City);
                        bodyFormData.append("PostalCode", this.user.PostalCode);
                        bodyFormData.append("Street", this.user.Street);
                        bodyFormData.append("StreetNumber", this.user.StreetNumber);
                        bodyFormData.append("FlatNumber", this.user.FlatNumber);
                        bodyFormData.append("Phone", this.user.Phone);
                        bodyFormData.append("Email", this.user.Email);
                        bodyFormData.append("Nationality", this.user.Nationality);
                        bodyFormData.append("CountryOfBirth", this.user.CountryOfBirth);
                        bodyFormData.append("Pesel", this.user.Pesel);
                        bodyFormData.append("DocumentNumber", this.user.DocumentNumber);
                        bodyFormData.append("DocumentType", this.user.DocumentType);
                        bodyFormData.append("ConsentAdditionalPayment", this.user.ConsentAdditionalPayment);
                        bodyFormData.append("ConsentPersonalData", this.user.ConsentPersonalData);
                        bodyFormData.append("ConsentRegulation", this.user.ConsentRegulation);
                        bodyFormData.append("ConsentExcluded", this.user.ConsentExcluded);
                        bodyFormData.append("PepStatement1", this.user.PepStatement1);
                        bodyFormData.append("PepStatement2", this.user.PepStatement2);
                        bodyFormData.append("PepStatement3", this.user.PepStatement3);
                        bodyFormData.append("StatementType", this.user.StatementType);
                        bodyFormData.append("Signature", this.user.Signature);
                        bodyFormData.append("BankAccount", this.user.BankAccount);
                        bodyFormData.append("ConsentRb", this.user.ConsentRb);
                        bodyFormData.append("Code", this.user.Code.toUpperCase().replaceAll('-', ''));
                        bodyFormData.append("SerialNumber", this.user.SerialNumber);
                        bodyFormData.append("Statement", this.user.Statement);
                        bodyFormData.append("token", token);
                        bodyFormData.append("hash", hash);
                        // var pi = document.getElementById("picture-input");
                        // var fi = pi.getElementsByTagName("input")[0].files[0];
                        // bodyFormData.append("file", fi);
                        // if (fi === undefined) {
                        //     this.imageStatus = "NOIMG";
                        // } else {
                        this.statementClick = true;
                        axios({
                            method: "post",
                            url: "PostTestimony",
                            data: bodyFormData,
                            config: {
                                headers: {
                                    "Content-Type": "multipart/form-data"
                                }
                            },
                        })
                            .then((response) => {
                                this.submitted = false;
                                this.$store.state.notification.state = true;
                                this.$store.state.notification.message =
                                    response.data.message;
                                this.$store.state.notification.status = response.data.status;
                            })
                            .catch((error) => {
                                this.submitted = false;
                            });
                        //}
                    }

                    this.user.Name = "";
                    this.user.Surname = "";
                    this.user.City = "";
                    this.user.PostalCode = "";
                    this.user.Street = "";
                    this.user.StreetNumber = "";
                    this.user.FlatNumber = "";
                    this.user.Phone = "";
                    this.user.Email = "";
                    this.user.Nationality = "";
                    this.user.CountryOfBirth = "";
                    this.user.Pesel = "";
                    this.user.DocumentNumber = "";
                    this.user.DocumentType = "";
                    this.user.ConsentRegulation = false;
                    this.user.ConsentExcluded = false;
                    this.user.ConsentAdditionalPayment = false;
                    this.user.PepStatement1 = "";
                    this.user.PepStatement2 = "";
                    this.user.PepStatement3 = "";
                    this.user.StatementType = "Type4";
                    this.user.ConsentRb = false;
                    this.user.Code = "";
                    this.user.Statement = "";
                    this.$refs.registerForm.reset();
                }
            } else {
                this.$store.state.notification.state = true;
                this.$store.state.notification.message =
                    "Proszę wypełnić wymagane pola w formularzu.";
                this.$store.state.notification.status = "error";
            }
        },
        clearPostData() { },
        formatDate(date) {
            var d = new Date(date),
                month = "" + (d.getMonth() + 1),
                day = "" + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
        },
        onChange(image) {
            if (image) {
                this.image = image;
                this.imageUpload = true;
                this.imageStatus = "PENDING";
            } else {
                this.imageStatus = "NOIMG";
                this.imageUpload = false;
            }
        },
    },
    components: {
        PEPInfo,
        Clause,
        ValidationObserver,
        ValidationProvider,
        // PictureInput
    },
};
</script>
